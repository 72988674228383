// dependencies
import Angular from "../../globals/angular.global";

import CurrenciesApiService from "./currencies.services.api";

// constant
export const name = "tcs.currencies.services";
export const apiServiceName = "tcs.currencies.services.api";

// module
Angular
    .module(name, [])
    .service(apiServiceName,
    [
        "$http",
        CurrenciesApiService
    ]);
