export default class CurrenciesApiService implements Currencies.Services.ICurrenciesApiService
{
    private _http: angular.IHttpService;
    private _resourceUrl: string;
    constructor($http: angular.IHttpService)
    {
        this._http = $http;
        this._resourceUrl = "api/currencies";
    }

    public getAll(): angular.IPromise<Currencies.Dto.ICurrencyDto[]>
    {
        return this._http
            .get(this._resourceUrl)
            .then((result: angular.IHttpPromiseCallbackArg<Currencies.Dto.ICurrencyDto[]>) =>
            {
                return result.data;
            });
    }
}
