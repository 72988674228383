export default class TaxesMainBladeController {
    private _travelerBladeNavigationService: any;
    private _orderBladeNavigationService: any;
    private _itemBladeNavigationService: any;
    private _coreNavigationService: any;
    private _currentBlade: any;
    private _parentBlade: any;

    constructor(
        public $scope: any,
        travelerBladeNavigationService: any,
        orderBladeNavigationService: any,
        itemBladeNavigationService: any,
        coreNavigationService: any
    ) {
        this._travelerBladeNavigationService = travelerBladeNavigationService;
        this._orderBladeNavigationService = orderBladeNavigationService;
        this._itemBladeNavigationService = itemBladeNavigationService;
        this._coreNavigationService = coreNavigationService;

        this._parentBlade = this.$scope.$parent.blade;
        this._currentBlade = this.$scope.blade;
        this.$scope.links = [];
        $scope.clickItem = (item: any) => {
            if (item.action) {
                item.action();
            }
            for (let i = 0; i < $scope.links.length; i++) {
                this.$scope.links[i].isSelected = false;
            }
            item.isSelected = true;
        };

        this._load();
    }

    private _load(): void {

        let itemTaxesLink =
            {
                name: "Item surcharges",
                icon: "fa fa-image",
                isSelected: false,
                action: () => {
                    this._itemBladeNavigationService
                        .showAllTaxesBlade(this._currentBlade);
                }
            };

        this.$scope.links.push(itemTaxesLink);

        this._currentBlade.isLoading = false;
    }
}
