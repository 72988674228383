// dependencies
import Angular from "../../globals/angular.global";

import * as createTaxBladeModule from "../../taxes/blades/tax-create-blade/tax-create-blade.module";
import * as updateTaxBladeModule from "../../taxes/blades/tax-update-blade/tax-update-blade.module";
import * as taxNavigationModule from "../../taxes/navigation/taxes.navigation.module";
import * as travellerTaxesModule from "../traveller-taxes/traveller-taxes.module";

import TravellerTaxesBladeController from "./traveller-taxes-blade.controller";

// constants
export const name = "tcs.surcharges.traveller.travaller-taxes-blade";
export const controllerName = "tcs.surcharges.traveller.travaller-taxes-blade.ctrl";
export const templateUrl = "tcs.surcharges.traveller.travaller-taxes-blade.template.html";

// module
Angular
    .module(name,
    [
        travellerTaxesModule.name,
        createTaxBladeModule.name,
        updateTaxBladeModule.name,
        taxNavigationModule.name
    ])
    .controller(controllerName,
    [
        "$scope",
        taxNavigationModule.navigationServiceName,
        TravellerTaxesBladeController
    ])
    .run(["$templateCache", ($templateCache: angular.ITemplateCacheService) => {
        let bladeTemplate = require("./traveller-taxes-blade.html");
        $templateCache.put(templateUrl, bladeTemplate);
    }]);
