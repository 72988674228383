export default class StoresApiService implements Stores.Services.IStoresApiService {
    private resourceUrl: string;
    private _http: angular.IHttpService;

    constructor($http: angular.IHttpService) {
        this.resourceUrl = "api/stores";
        this._http = $http;
    }
    public getAll() {
        return this._http
            .get(this.resourceUrl)
            .then((result: any) => {
                return result.data;
            });
    }
}
