// dependencies
import Angular from "../../globals/angular.global";

import * as taxesListModule from "../../taxes/components/taxes-list/taxes-list.module";
import * as taxServicesModule from "../../taxes/services/taxes.services.module";

import OrderTaxesController from "./order-taxes.controller";

// constants
export const name = "tcs.surcharges.order";
const controllerName = "tcs.surcharges.order.ctrl";
const selectorName = "tcsOrderSurcharges";

// module
Angular
    .module(name,
    [
        taxServicesModule.name,
        taxesListModule.name
    ])
    .controller(controllerName,
    [
        taxServicesModule.apiServiceName,
        OrderTaxesController
    ])
    .directive(selectorName, () => {
        let directive: angular.IDirective =
            {
                restrict: "E",
                template: require("./order-taxes.html"),
                scope:
                {
                    listChangedEventHandlers: "=",
                    toolbar: "=",
                    onLoadStarted: "&",
                    onLoadCompleted: "&",
                    onCreateClicked: "&",
                    onUpdateClicked: "&"
                },
                controller: controllerName,
                controllerAs: "vm",
                bindToController: true
            };
        return directive;
    });
