﻿import Angular from "./globals/angular.global";

import * as platform from "./globals/platform/platform";
import * as mainBladeModule from "./main-blade/main-blade.module";

export const name = "tcs.surcharges";

Angular
    .module(name,
    [
        "tcs.module.myarline.manager",
        mainBladeModule.name
    ]);

let appDependencies = (<any>window).AppDependencies;
if (appDependencies) {
    appDependencies.push(name);
}
