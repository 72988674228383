// dependencies
import Angular from "../../globals/angular.global";

import * as platform from "../../globals/platform/platform";
import * as travellerTaxesBladeModule from "../traveller-taxes-blade/traveller-taxes-blade.module";

import travellerBladeNavigationService from "./travaller.blade.navigation";

// constants
export const name = "tcs.surcharges.traveller.services";
export const navigationServiceName = "tcs.surcharges.traveller.services.blade-navigation";

// module
Angular
    .module(name,
    [
        platform.moduleName,
        travellerTaxesBladeModule.name
    ])
    .service(navigationServiceName,
    [
        platform.bladesServiceName,
        travellerBladeNavigationService
    ]);
