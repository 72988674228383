export default class TravellerTaxesBladeController {
    constructor(public $scope: any, taxesNavigationService: Taxes.Navigation.ITaxesBladeNavigationService) {
        $scope.dataChangedEventHandlers = [];
        $scope.blade.onTaxListChanged = () => {
            // execute all subscriptions to data
            $scope.dataChangedEventHandlers.forEach((handler: any) => {
                if (typeof handler === "function") {
                    handler();
                }
            });
        };

        $scope.showTaxCreateBlade = () => {
            taxesNavigationService.showCreateBlade($scope.blade, TaxLevel.Traveller);
        };
        $scope.showTaxUpdateBlade = (tax: Taxes.Dto.ITaxDto) => {
            taxesNavigationService.showUpdateBlade($scope.blade, tax);
        };
        $scope.showLoadbar = () => {
            $scope.blade.isLoading = true;
        };
        $scope.hideLoadbar = () => {
            $scope.blade.isLoading = false;
        };
    }
}
