import * as templateUrls from "../templates/index";

export default class TaxExpressionsApiService implements Taxes.Expressions.Services.IExpressionsApiService {
    private _http: angular.IHttpService;
    private _resourceUrl: string;
    private _dynamicExpressionsService: Globals.Core.IDynamicExpressionsService;
    private _extendElementBlock: (expressionBlock: Globals.Core.IExpressionTree) => void;

    constructor($http: angular.IHttpService, dynamicExpressionsService: Globals.Core.IDynamicExpressionsService) {
        this._http = $http;
        this._resourceUrl = "api/travel/surchargesexpressions";
        this._dynamicExpressionsService = dynamicExpressionsService;

        this._registerAgeCondition();
        this._registerCountryCondition();
        this._registerStationCondition();
        this._registerClassCondition();
        this._registerProductCondition();

        this._extendElementBlock = (expressionBlock: Globals.Core.IExpressionTree) => {
            let current = <Globals.Core.IExpressionTree>this._dynamicExpressionsService.expressions[expressionBlock.id];
            if (!current) {
                current = <Globals.Core.IExpressionTree>{ displayName: "unknown element: " + expressionBlock.id };
            }

            expressionBlock.templateURL = current.templateURL;
            expressionBlock.newChildLabel = current.newChildLabel;
            expressionBlock.displayName = current.displayName;
            expressionBlock.getValidationError = current.getValidationError;

            expressionBlock.data = current.data;

            if (!expressionBlock.children) {
                expressionBlock.children = [];
            }

            expressionBlock.children
                .forEach((x: Globals.Core.IExpressionTree) => { this._extendElementBlock(x); });
            expressionBlock.availableChildren
                .forEach((x: Globals.Core.IExpressionTree) => { this._extendElementBlock(x); });
        };
    }
    public getDefaultTree(level: TaxLevel): angular.IPromise<Globals.Core.IExpressionTree> {
        let requestUrl = `${this._resourceUrl}/${level}`;
        return this._http
            .get(requestUrl)
            .then((result: any) => {
                if (!result || !result.data) {
                    return null;
                }
                result.data.children.forEach((x: any) => {
                    this._extendElementBlock(x);
                });
                return result.data;
            });
    }

    private _registerAgeCondition(): Globals.Core.IExpressionTree {
        let ageCondition = <Globals.Core.IExpressionTree>
            {
                id: "AgeCondition",
                availableChildren: [],
                children: [],
                displayName: "",
                newChildLabel: "+ add traveller type",
                templateURL: templateUrls.ageConditionTemplateUrl,
                getValidationError: () => undefined,
                data: { all: true }
            };

        ageCondition.availableChildren.push(this._createIsChildCondition());
        ageCondition.availableChildren.push(this._createIsAdultCondition());
        ageCondition.availableChildren.push(this._createIsInfantCondition());

        this._dynamicExpressionsService.registerExpression(ageCondition);
        return ageCondition;
    }

    private _createIsChildCondition(): Globals.Core.IExpressionTree {
        let isChildCondition = <Globals.Core.IExpressionTree>
            {
                id: "IsChildCondition",
                displayName: "Is child",
                templateURL: templateUrls.ageConditionIsChildTemplateUrl
            };

        this._dynamicExpressionsService.registerExpression(isChildCondition);
        return isChildCondition;
    }
    private _createIsAdultCondition(): Globals.Core.IExpressionTree {
        let isAdultCondition = <Globals.Core.IExpressionTree>
            {
                id: "IsAdultCondition",
                displayName: "Is adult",
                templateURL: templateUrls.ageConditionIsAdultTemplateUrl
            };

        this._dynamicExpressionsService.registerExpression(isAdultCondition);
        return isAdultCondition;
    }
    private _createIsInfantCondition() {
        let isInfantCondition = <Globals.Core.IExpressionTree>
            {
                id: "IsInfantCondition",
                displayName: "Is infant",
                templateURL: templateUrls.ageConditionIsInfantTemplateUrl
            };
        this._dynamicExpressionsService.registerExpression(isInfantCondition);
        return isInfantCondition;
    }

    private _registerCountryCondition(): Globals.Core.IExpressionTree{
        let сountryCondition = <Globals.Core.IExpressionTree>
        {
            id: "CountryCondition",
            availableChildren: [],
            children: [],
            displayName: "",
            newChildLabel: "+ add country",
            templateURL: templateUrls.countryConditionTemplateUrl,
            getValidationError: () => undefined,
            data: { all: true }
        };
        сountryCondition.availableChildren.push(this._createDepartsCountry());
        сountryCondition.availableChildren.push(this._createArrivesCountry());

        this._dynamicExpressionsService.registerExpression(сountryCondition);
        return сountryCondition;
    }

    private _createDepartsCountry() {
        let departureCountry = <Globals.Core.IExpressionTree>
            {
                id: "DepartsCountryCondition",
                displayName: "departure country",
                templateURL: templateUrls.countryConditionIsDepartsTemplateUrl,
                data: { CountryCode: "select country"}
            };
        this._dynamicExpressionsService.registerExpression(departureCountry);
        return departureCountry;
    }

    private _createArrivesCountry() {
        let departureCountry = <Globals.Core.IExpressionTree>
            {
                id: "ArrivesCountryCondition",
                displayName: "arrival country",
                templateURL: templateUrls.countryConditionIsArrivesTemplateUrl,
                data: { CountryCode: "select country"}
            };
        this._dynamicExpressionsService.registerExpression(departureCountry);
        return departureCountry;
    }

    private _registerStationCondition(): Globals.Core.IExpressionTree{
        let stationCondition = <Globals.Core.IExpressionTree>
        {
            id: "StationCondition",
            availableChildren: [],
            children: [],
            displayName: "",
            newChildLabel: "+ add station",
            templateURL: templateUrls.stationConditionTemplateUrl,
            getValidationError: () => undefined,
            data: { all: true }
        };
        stationCondition.availableChildren.push(this._createDepartsStation());
        stationCondition.availableChildren.push(this._createArrivesStation());

        this._dynamicExpressionsService.registerExpression(stationCondition);
        return stationCondition;
    }

    private _createDepartsStation() {
        let departureStation = <Globals.Core.IExpressionTree>
            {
                id: "DepartsStationCondition",
                displayName: "departure station",
                templateURL: templateUrls.stationConditionIsDepartsTemplateUrl,
                data: { StationCode: "select station"}
            };
        this._dynamicExpressionsService.registerExpression(departureStation);
        return departureStation;
    }

    private _createArrivesStation() {
        let departureStation = <Globals.Core.IExpressionTree>
            {
                id: "ArrivesStationCondition",
                displayName: "arrival station",
                templateURL: templateUrls.stationConditionIsArrivesTemplateUrl,
                data: { StationCode: "select station"}
            };
        this._dynamicExpressionsService.registerExpression(departureStation);
        return departureStation;
    }

    private _registerClassCondition(): Globals.Core.IExpressionTree{
        let classCondition = <Globals.Core.IExpressionTree>
        {
            id: "ClassCondition",
            availableChildren: [],
            children: [],
            displayName: "",
            newChildLabel: "+ add class",
            templateURL: templateUrls.classConditionTemplateUrl,
            getValidationError: () => undefined,
            data: { all: true }
        };
        classCondition.availableChildren.push(this._createClassItemConditiony());
        this._dynamicExpressionsService.registerExpression(classCondition);
        return classCondition;
    }

    private _createClassItemConditiony() {
        let classitemCondition = <Globals.Core.IExpressionTree>
            {
                id: "ClassItemCondition",
                displayName: "add class",
                templateURL: templateUrls.classItemTemplateUrl,
                data: { ClassCode: "selectClass"}
            };
        this._dynamicExpressionsService.registerExpression(classitemCondition);
        return classitemCondition;
    }

    private _registerProductCondition(): Globals.Core.IExpressionTree{
        let productCondition = <Globals.Core.IExpressionTree>
        {
            id: "ProductCondition",
            availableChildren: [],
            children: [],
            displayName: "",
            newChildLabel: "+ add product",
            templateURL: templateUrls.productConditionTemplateUrl,
            getValidationError: () => undefined,
            data: { all: true }
        };
        productCondition.availableChildren.push(this._createProductItemConditiony());
        this._dynamicExpressionsService.registerExpression(productCondition);
        return productCondition;
    }

    private _createProductItemConditiony() {
        let productitemCondition = <Globals.Core.IExpressionTree>
            {
                id: "ProductItemCondition",
                displayName: "add product",
                templateURL: templateUrls.productItemTemplateUrl,
                data: { ProductCode: "select product"}
            };
        this._dynamicExpressionsService.registerExpression(productitemCondition);
        return productitemCondition;
    }
}
