// dependencies
import Angular from "../../../globals/angular.global";

import * as expressionsServicesModule from "../../expressions/services/expressions.services.module";

import * as taxCreateModule from "../../components/tax-create/tax-create.module";

import TaxCreateBladeController from "./tax-create-blade.controller";

// constants
export const name = "tcs.surcharges.tax-create-blade";
export const controllerName = "tcs.surcharges.tax-create-blade.ctrl";
export const templateUrl = "tcs.surcharges.tax-create-blade.template.html";

// module
Angular
    .module(name,
    [
        taxCreateModule.name,
        expressionsServicesModule.name
    ])
    .controller(controllerName,
    [
        "$scope",
        expressionsServicesModule.uiServiceName,
        TaxCreateBladeController
    ])
    .run(["$templateCache",($templateCache: angular.ITemplateCacheService) =>
    {
        let bladeTemplate = require("./tax-create-blade.html");
        $templateCache.put(templateUrl, bladeTemplate);
    }]);
